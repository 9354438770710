import React, { useState } from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input, message } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { get, post } from "./../../utils/ajax";
import { useNavigate } from "react-router-dom";

const Login = (param) => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    var res = await post("/user/login", values);
    console.log("res -> :", res);
    if (res.status === "error") {
      message.error(res.msg);
      return;
    }
    message.success("success");
    localStorage.setItem("user", JSON.stringify(res));
    navigate("/");
    location.reload()
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Email address" name="email">
        <Input
          placeholder="Your Email address"
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      {/* <Form.Item label="User Name" name="user_name">
        <Input
          placeholder="User Name"
          style={{
            width: "100%",
          }}
        />
      </Form.Item> */}

      <Form.Item label="Your Password" name="password">
        <Input.Password
          style={{
            width: "100%",
          }}
          placeholder="Your Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const Reg = ({ successCallback }) => {
  const onFinish = async (values) => {
    var res = await post("/user/register", values);
    if (res.status === "error") {
      message.error(res.msg);
      return;
    }
    message.success(
      "Registration successful!,Please go to the email to activate your account!"
    );
    successCallback && successCallback();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="User Name" name="user_name">
        <Input
          placeholder="User Name"
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      <Form.Item label="Email address" name="email">
        <Input
          placeholder="Your Email address"
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      <Form.Item label="Your Password" name="password">
        <Input.Password
          style={{
            width: "100%",
          }}
          placeholder="Your Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Sign up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default function Index() {
  const [type, setType] = useState(1);

  return (
    <div className={style.wrap}>
      <div className={style.center}>
        <div className={style.formWrap}>
          {type == 1 && <Login />}
          {type == 2 && <Reg successCallback={() => setType(1)} />}

          <div className={style.link}>
            {type === 1 ? (
              <>
                {/* <a onClick={() => setType(1)}>Send a magic link email</a> */}
                {/* <a onClick={() => setType(1)}>Forgot your password?</a> */}
                <a onClick={() => setType(2)}>Don't have an account? Sign up</a>
              </>
            ) : (
              <a onClick={() => setType(1)}>Already have an account? Sign in</a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
