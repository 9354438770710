export const baseUrl = "https://www.speci-chatbot.com/api";

// process.env.NODE_ENV == "development"
//   ? "/api"
//   : process.env.REACT_APP_BASE_URL;

const objToParams = (param = "") =>
  param
    ? "?" +
      Object.keys(param)
        .map((key) => `${key}=${param[key]}`)
        .join("&")
    : "";

export const get = (url, data, headers = {}) =>
  new Promise((resolve, reject) => {
    fetch(baseUrl + url + objToParams(data), {
      method: "GET",
      headers: {
        ...headers,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.msg == "token无效" && result.status == "error") {
          location.href = "/signin";
          return resolve(result);
        } else {
          return resolve(result);
        }
      })
      .catch((error) => {
        console.log('error -> :', error)
        resolve(null);
        return null;
      });
  });

export const getFile = (url, data, headers = {}) =>
  new Promise((resolve, reject) => {
    fetch(baseUrl + url + objToParams(data), {
      method: "GET",
      headers: {
        ...headers,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        downloadBlob(blob, "chatbot.txt");
        resolve(null);
      })
      .catch((error) => {
        resolve(null);
        return null;
      });
  });

const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.style.display = "none";
  document.body.appendChild(a);
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const post = (url, body, headers = {}) => {
  const data = new FormData();

  Object.keys(body).forEach((key) => {
    if (Array.isArray(body[key])) {
      body[key].forEach((second) => {
        data.append(key, second);
      });
    } else {
      data.append(key, body[key]);
    }
  });

  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        ...headers,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.msg == "token无效" && result.status == "error") {
          location.href = "/signin";
          return resolve(result);
        } else {
          return resolve(result);
        }
      })
      .catch((error) => {
        resolve(null);
        return null;
      });
  });
};

export const jsonPost = (url, body, headers = {}) => {
  const data = JSON.stringify(body);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, {
      method: "POST",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.msg == "token无效" && result.status == "error") {
          // location.href = "/signin";
          return resolve(result);
        } else {
          return resolve(result);
        }
      })
      .catch((error) => {
        resolve(null);
        return null;
      });
  });
};
