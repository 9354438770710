import React, { useEffect, useRef, useState } from "react";
import Icon, { RedoOutlined, CloseOutlined } from "@ant-design/icons";
import { Space, Tag, Input } from "antd";
import styles from "./style.module.scss";
import { get, post } from "./../../utils/ajax";
import createCompletion from "./../../utils/chat";
import { useReactive } from "ahooks";
import logo from "./../../assets/logo.png";

const SendIcon = (props) => (
  <Icon
    component={() => (
      <svg viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
        <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"></path>
      </svg>
    )}
    {...props}
  />
);

const user = JSON.parse(localStorage.getItem("user") || "{}");
const lsSessionID = localStorage.getItem("sessionID") || "";

const defaultList = [
  { text: "Hi!", type: "bot" },
  {
    text: "you can create a chatbot like me for your website!",
    type: "bot",
  },
];

export default function Index({ type, cancel, chatbot_id, user_id }) {
  const chat = useRef(null);

  const info = useReactive({
    user_id: 1,
    chatbot_id: 1,
  });

  const getSession = (type) => {
    post("/chats_bp/new_session", info)
      .then((res) => {
        if (res) {
          if (type == "logined") {
            setLoginedSessionID(res.sessionID);
          } else {
            setSessionID(res.sessionID);
            localStorage.setItem("sessionID", res.sessionID);
          }
        }
      })
      .catch((err) => {
        console.log("getSession err -> :", err);
      });
  };

  useEffect(() => {
    if (user.token) {
      info.user_id = user_id || user.user_id;
    }
    if (chatbot_id) {
      info.chatbot_id = chatbot_id;
      getSession("logined");
    } else if (!lsSessionID) {
      getSession();
    }

    msgList.list = [...defaultList];
  }, []);

  const [sessionID, setSessionID] = useState(null);
  const [loginedSessionID, setLoginedSessionID] = useState(null);

  const [isStart, setIsStart] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendChat = useReactive({
    msg: "",
  });

  let msgList = useReactive({
    list: [],
  });

  const sendMsg = async () => {
    if (!sendChat.msg) return;
    setIsStart(true);
    const oldChat = { ...info, ...sendChat };
    msgList.list.push({ text: sendChat.msg, type: "user" });
    oldChat.sessionID = loginedSessionID || lsSessionID || sessionID;
    sendChat.msg = "";
    setLoading(true);
    msgList.list.push({ text: "", type: "bot" });
    createCompletion(oldChat, {
      onmessage: (rs) => {
        // console.log("rs -> :", rs);
        const oldText = msgList.list[msgList.list.length - 1].text;
        let newText = "";
        newText = oldText + rs;
        msgList.list[msgList.list.length - 1].text = newText;
        chat.current.scrollTop = chat.current.scrollHeight - 40;
      },
      error: (err) => {
        console.log("err -> :", err);
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    chat.current.scrollTop = chat.current.scrollHeight - 40;
    // }
  }, [msgList.list.length]);

  return (
    <div style={{ overflow: "auto", height: "600px" }} ref={chat}>
      <div className={styles.chat}>
        <div className={styles.top}>
          <div className={`${styles.mylogo} logo`}>
            {!chatbot_id && (
              <>
                <img
                  // src="https://backend.chatbase.co/storage/v1/object/public/chat-icons/4a84dc98-32e3-4cf7-9aaf-9df171bd7f8a/chatbase--1--pdf-p680fxvnm.svg"
                  src={logo}
                  alt=""
                />
                SpeciChatbot
              </>
            )}
          </div>

          <Space>
            <RedoOutlined
              onClick={() => {
                msgList.list = [...defaultList];
                console.log("defaultList -> :", defaultList);
                console.log("msgList -> :", msgList);
              }}
            />
            {!chatbot_id && type !== "page" && (
              <CloseOutlined
                onClick={() => {
                  cancel && cancel();
                }}
              />
            )}
          </Space>
        </div>

        <div className={styles.content}>
          {msgList.list.map((item, index) => {
            return (
              <div
                className={
                  item.type === "bot" ? styles.chatLeft : styles.chatRight
                }
                key={index}
              >
                <div>{item.text}</div>
              </div>
            );
          })}
        </div>

        <div className={styles.text}>
          {!chatbot_id && (
            <p className={styles.tags}>
              <Tag
                bordered={false}
                onClick={() => {
                  sendChat.msg = "What is SpeciChatbot?";
                  sendMsg();
                }}
              >
                What is SpeciChatbot?
              </Tag>
              <Tag
                bordered={false}
                onClick={() => {
                  sendChat.msg = "How do I add data to my chatbot?";
                  sendMsg();
                }}
              >
                How do I add data to my chatbot?
              </Tag>
            </p>
          )}
          <div className={styles.input}>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 6 }}
              size="large"
              placeholder="Type your message here"
              value={sendChat.msg}
              onChange={(e) => {
                sendChat.msg = e.target.value;
                // setSendChat({ ...sendChat, msg: e.target.value });
              }}
              onPressEnter={() => sendMsg()}
            />

            <SendIcon className={styles.send} onClick={() => sendMsg()} />
          </div>

          {!chatbot_id && (
            <p className={styles.powered}>
              Powered By{" "}
              <a target="_blank" href="/">
                speci-chatbot.com
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
