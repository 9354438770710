import React from "react";
import style from "./../style.module.scss";

export default function Started() {
  return (
    <div className={style.start}>
      <h1>Getting Started With Your Chatbot Settings</h1>
      <br />
      <p>
        This guide assists you in navigating through the SpeciChatbot chatbot
        settings page, offering insights into surmounting prevalent challenges.
        Consequently, you will be empowered to optimize your chatbot’s
        functionality with precision.
      </p>
      <br />
      <br />

      <h2>General Settings</h2>
      <br />
      <p>
        The settings page of your chatbot serves as its control hub, a dedicated
        space where pivotal modifications to the bot’s behavior and
        functionality can be executed. Allow us to delve into each individual
        setting, elucidating their respective roles and contributions to the
        overall performance of your chatbot.
      </p>
      <br />
      <br />

      <h2>Base Prompt</h2>
      <br />
      <p>
        The base prompt serves as a foundational element enabling you to define
        the character and interactive style of your chatbot, essentially
        bestowing it with a distinct personality. This crucial feature is
        adjustable and modifiable to align seamlessly with your specific
        requirements and preferences. For instance, you may:
      </p>
      <br />
      <br />

      <p>
        You can adjust and modify the base prompt to better suit your needs. For
        example, you can:
      </p>

      <ul>
        <li>
          <strong>Modify the bot's personality</strong>
          <ul>
            <li>
              Should you desire your bot to embody a tone that is both casual
              and amicable, consider experimenting with a phrase akin to the
              following within your base prompt: 'You are a friendly and casual
              AI Assistant.
            </li>
          </ul>
        </li>
        <li>
          <strong>Change how the bot responds to unknown queries</strong>
          <ul>
            <li>
              Rather than having your chatbot respond with a noncommittal 'Hmm,
              I am not sure.', you might prefer it to convey, 'I apologize, but
              I lack the information you seek. Kindly reach out to customer
              support for assistance."
            </li>
          </ul>
        </li>
        <li>
          <strong>Direct its focus on certain topics</strong>
          <ul>
            <li>
              Should you wish for your bot to exhibit expertise in a specific
              domain, you might incorporate a statement like, "You are an AI
              Assistant proficient in dispensing information related to
              environmental sustainability."
            </li>
          </ul>
        </li>
        <li>
          <strong>Define its boundaries</strong>
          <ul>
            <li>
              “If you intend to limit your bot from disseminating specific
              categories of information, you may explicitly state, ”Refrain from
              providing financial advice or information.”
            </li>
          </ul>
        </li>
      </ul>

      <br />
      <p>
        Through meticulous customization of your base prompt, you can not only
        guarantee that your bot furnishes essential information but also assure
        it communicates in a manner consistent with your brand’s distinctive
        voice and core values. Additionally, this fine-tuning aids in minimizing
        your bot’s susceptibility to generating hallucinations and restricts it
        from addressing queries beyond the confines of the furnished data.
      </p>
      <br />
      <br />

      <h2>Model</h2>
      <br />
      <p>
        This particular setting empowers you to select your preferred Open AI
        model for the chatbot’s use. Initially, it defaults to the gpt-3.5-turbo
        model, consuming one credit per message. Alternatively, you may choose
        the gpt-4 model, renowned for delivering responses with heightened
        accuracy, albeit at the cost of 20 credits per message. In situations
        where credits are depleted while subscribed to the Unlimited plan,
        there’s the provision to utilize your personal Open AI API key. Secure a
        position on the waitlist for obtaining a gpt-4 API key at the specified
        link. Subsequently, enter your API key within the Account Settings.
      </p>
      <br />
      <br />

      <h2>Temperature</h2>
      <br />
      <p>
        The 'temperature' setting is synonymous with the level of 'creativity'
        exhibited in the bot’s responses. With a default value of zero, this
        parameter guides your chatbot to select the most probable output during
        response generation, yielding answers that are consistently coherent and
        minimally arbitrary. Feel free to modify this numerical value and engage
        in experimentation with your bot to finely tailor its performance to
        meet your unique needs.
      </p>
      <br />
      <br />

      <h2>Visibility</h2>
      <br />
      <p>
        You have the capability to configure the visibility of your chatbot by
        opting for either 'private' or 'public' settings. Selecting 'private'
        ensures exclusive access to the bot for yourself, eliminating the
        possibility of embedding it on a website. Conversely, choosing 'public'
        allows universal access to your chatbot for anyone possessing the link,
        provided you distribute the sharing link. This setting also enables the
        chatbot's embedding functionality on your website.
      </p>
      <br />
      <br />

      <h2>Domains</h2>
      <br />
      <p>
        Please designate the website domains within the provided text box where
        you intend to embed your chatbot.
      </p>
      <br />
      <br />

      <h2>Usage Limit Settings</h2>
      <br />
      <p>
        You have the option to solicit users for their name, email address,
        and/or phone number upon initiating interaction with the chatbot.
        Customer information thus collected is readily accessible within the
        dashboard under the 'Leads' tab. Furthermore, the acquired data, along
        with conversation histories, can be conveniently downloaded in CSV
        format for the former and JSON format for the latter.
      </p>
      <br />
      <br />

      <h2>Chat Interface</h2>
      <br />
      <p>
        You possess the ability to enhance the user interface and user
        experience of the chatbot through fine-tuning elements such as 'initial
        messages', 'suggested messages', theme, logo, alignment, and color
        palette. More pointedly, consider crafting the 'initial message' and
        'suggested messages' with precision to offer lucid guidance to users.
        The 'initial message' acts as the inaugural interaction point between
        your chatbot and the users, establishing the communication tone and
        encouraging user engagement. Meanwhile, 'suggested messages' serve as
        subtle prompts, guiding users toward specific inquiries. They can be
        strategically designed to address frequently posed questions or
        spotlight crucial facets of your service, with examples like 'What is
        example.com?' or 'How does it work?'. Effectively, these nuanced
        customizations shepherd users through their engagement journey.
      </p>
      <br />
      <br />

      <h2>Notification Settings</h2>
      <br />
      <p>
        Your notification settings grant you the option to receive email updates
        containing chat logs from your bot. To activate notifications, simply
        enter an email address and select 'Add Email'.
      </p>
      <br />
      <br />

      <h2>Next steps</h2>
      <br />
      <p>
        Grasping the nuances of each setting and tweaking them to align with
        your business requirements allows you to unlock the full potential of
        your SpeciChatbot. An optimized and well-orchestrated setup ensures that
        users enjoy seamless, intuitive, and efficacious interaction experiences
        with your bot. For additional insights on enhancing user experience
        through SpeciChatbot, don't miss our upcoming article titled 'Optimizing
        SpeciChatbot Responses'.
      </p>
    </div>
  );
}
