import React from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { Routes, Route, Outlet, Link } from "react-router-dom";

export default function Index() {
  return (
    <div className={style.wrap}>
      <div className={style.center}>
        <h1>Guide</h1>
        <p>
          <Link to="/started">Getting Started With Your Chatbot Settings</Link>
        </p>
        <p>
          <Link to="/response">Response Quality</Link>
        </p>
      </div>
    </div>
  );
}
