import React from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Popover, FloatButton } from "antd";
import { TwitterOutlined, CommentOutlined } from "@ant-design/icons";
import Chat from "../../components/Chat";
import tbg from "./img/t-bg.png";

export default function Index() {
    const [open, setOpen] = React.useState(false);
    const hide = () => {
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    return (
        <div className={style.wrap}>
            <div className={`${style.titleWrap} sub-title-padding`}>
                <Row>
                    <Col
                        lg={12}
                        md={24}
                        xs={24}
                        sm={24}
                    >
                        <div className={style.title}>
                            <h1>
                                Your Own Data Your own Chatgpt
                                <br />
                                <span>Your personal assistant</span>
                            </h1>
                            <p>
                                Upload your data, craft a custom ChatGPT-4 bot, and seamlessly embed
                                it into your site. Your data, your personalized chat experience.
                            </p>
                            <div>
                                <Button type="primary">Build Your Chatbot</Button>
                            </div>
                        </div>
                    </Col>
                    <Col
                        lg={12}
                        md={24}
                        xs={24}
                        sm={24}
                    >
                        <div className={style.cententRight}>
                            <img
                                alt="product hunt"
                                // src="https://backend.chatbase.co/storage/v1/object/public/chatbase/landing/slack-hero.png?width=640&quality=50"
                                src={tbg}
                                width="100%"
                                decoding="async"
                                data-nimg="1"
                                loading="lazy"
                                style={{ color: "transparent" }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={style.video}>
                <h4 className={style.h4}>Video Demo</h4>

                <p>
                    Developing a chatbot for Product Hunt by extracting data from the site and
                    educating the AI with its material. I can subsequently integrate this chatbot
                    directly into the website!
                </p>

                <div>
                    <iframe
                        style={{ width: "100%", height: "700px" }}
                        src="https://www.youtube.com/embed/icLX_rJE_Mo?si=ZKPBb6W8nKfcJUrx"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>

                    {/* <video muted loop controls>
            <source src="https://youtu.be/icLX_rJE_Mo?si=JZYsefiSHVUB3Dw6" />
          </video> */}
                </div>
            </div>

            <div className={style.demo}>
                <h4 className={style.h4}>Live Demo</h4>

                <p>
                    This chatbot has been educated using a document about
                    {/* This chatbot was trained on a document explaining */}
                    <span> SpeciChatbot</span>
                </p>

                {/* <p>You can embed a widget like this on any page on your website!</p> */}
                <p>You can incorporate a widget of this kind on any section of your website!</p>

                <div>
                    <Chat type="page" />
                </div>
            </div>

            <div className={style.icons}>
                <div className={style.text}>
                    <p>Chatbase integrates with your favorite tools</p>
                </div>
                <div className={style.content}>
                    <Row
                        gutter={20}
                        align="middle"
                    >
                        <Col
                            lg={6}
                            md={12}
                            xs={24}
                            sm={24}
                        >
                            <Card
                                style={{ boxShadow: 0 }}
                                bordered={false}
                                cover={
                                    <img
                                        style={{ width: "180px" }}
                                        alt="example"
                                        src="https://s.w.org/style/images/about/WordPress-logotype-standard.png"
                                    />
                                }
                            >
                                <Card.Meta
                                    className={style.cardMeta}
                                    title={<>Coming soon...</>}
                                />
                            </Card>
                        </Col>
                        <Col
                            lg={6}
                            md={12}
                            xs={24}
                            sm={24}
                        >
                            <Card
                                style={{ boxShadow: 0 }}
                                bordered={false}
                                cover={
                                    <img
                                        style={{ width: "180px" }}
                                        alt="example"
                                        src="https://1000logos.net/wp-content/uploads/2022/09/Zapier-Logo.png"
                                    />
                                }
                            >
                                <Card.Meta
                                    className={style.cardMeta}
                                    title={<>Coming soon...</>}
                                />
                            </Card>
                        </Col>
                        <Col
                            lg={6}
                            md={12}
                            xs={24}
                            sm={24}
                        >
                            <Card
                                style={{ boxShadow: 0 }}
                                bordered={false}
                                cover={
                                    <img
                                        style={{ width: "180px" }}
                                        alt="example"
                                        src="https://logos-world.net/wp-content/uploads/2020/10/Slack-Logo.png"
                                    />
                                }
                            >
                                <Card.Meta
                                    className={style.cardMeta}
                                    title={<>Coming soon...</>}
                                />
                            </Card>
                        </Col>
                        <Col
                            lg={6}
                            md={12}
                            xs={24}
                            sm={24}
                        >
                            <Card
                                style={{ boxShadow: 0 }}
                                bordered={false}
                                cover={
                                    <img
                                        style={{ width: "180px" }}
                                        alt="example"
                                        src="https://tse2-mm.cn.bing.net/th/id/OIP-C.-75PqQspc1xVhJQR7f4TywHaBu?pid=ImgDet&rs=1"
                                    />
                                }
                            >
                                <Card.Meta
                                    className={style.cardMeta}
                                    title={<>Coming soon...</>}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className={style.ques}>
                <h4 className={style.h4}>Frequently Asked Questions</h4>

                <h6 className={style.h6}>
                    If you can't find your question, email <span>support@speci-chatbot.com</span>
                </h6>

                <Row
                    gutter={20}
                    className={style.qsContent}
                >
                    <Col
                        lg={12}
                        md={12}
                        xs={24}
                        sm={24}
                    >
                        <ul>
                            <li>
                                <h3>What is SpeciChatbot?</h3>
                                <p>
                                    SpeciChatbot is an AI-driven chatbot constructor. It fine-tunes
                                    ChatGPT using your data, allowing you to integrate a chat widget
                                    onto your site. Simply upload a document or provide a link to
                                    your website, and receive a chatbot capable of addressing
                                    queries related to its content
                                </p>
                            </li>
                            <li>
                                <h3>What should my data look like?</h3>
                                <p>
                                    At present, you can upload individual or multiple files
                                    (formats: .pdf, .txt, .doc, .docx), input text directly, or
                                    provide a link to your website for data extraction.
                                </p>
                            </li>
                            <li>
                                <h3>Can I give my chatbots instructions?</h3>
                                <p>
                                    Certainly, you can modify the foundational prompt, assign a name
                                    to your chatbot, imbue it with distinct personality
                                    characteristics, and provide directives on its response style,
                                    such as 'respond exclusively in Italian.
                                </p>
                            </li>
                            <li>
                                <h3>Where is my data stored?</h3>
                                <p>
                                    The document's content resides on secure GCP/AWS servers located
                                    in the US East region.
                                </p>
                            </li>
                        </ul>
                    </Col>
                    <Col
                        lg={12}
                        md={12}
                        xs={24}
                        sm={24}
                    >
                        <ul
                            role="list"
                            className="flex flex-col gap-y-8"
                        >
                            <li>
                                <h3>Does it use GPT-3.5 or GPT-4?</h3>
                                <p>
                                    By default, your chatbot employs gpt-3.5-turbo. However, the
                                    option to utilize gpt-4 will be available on the Standard and
                                    Unlimited plans very soon.
                                </p>
                            </li>
                            <li>
                                <h3>How can I add my chatbot to my website?</h3>
                                <p>
                                    You can integrate an iframe or position a chat bubble at the
                                    bottom right corner of your website. To achieve this, generate a
                                    chatbot and select "Embed on website". Additionally, you can
                                    leverage the API to interact with your chatbot from any
                                    location!
                                </p>
                            </li>
                            <li>
                                <h3>Does it support other languages?</h3>
                                <p>
                                    Absolutely, Chatbase is compatible with roughly 95 languages.
                                    You can present your sources in any of these languages and make
                                    inquiries in any language of your choice.
                                </p>
                            </li>
                            <li>
                                <h3>Can I share a chatbot I created?</h3>
                                <p>
                                    Indeed, by default, every chatbot you craft is set to private.
                                    However, you can adjust this setting to make it public and share
                                    it with anyone.
                                </p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>

            {/* 
      <div className={style.people}>
        <h4 className={style.h4}>What people say about SpeciChatbot</h4>

        <Row gutter={20}>
          <Col span={6}>
            <Card className={style.peopleCard} bordered hoverable>
              <p>
                <TwitterOutlined />
              </p>
              <p>“This is awesome”</p>
              <div className={style.peo}>
                <img
                  // src="https://pbs.twimg.com/profile_images/1642428370091925505/xknh7v7j_400x400.jpg"
                  src=""
                  alt=""
                />
                <div>
                  <div className={style.job}>Jacob Andreou</div>
                  <div>SVP @Snap</div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card className={style.peopleCard} hoverable>
              <p>
                <TwitterOutlined />
              </p>
              <p className={style.centerText}>
                “For anyone on the fence - in the 2 hours since this tweet I've
                tested, set up and gone live on three separate websites with
                SpeciChatbot. Still need to do some light tweaking of the base
                prompt but very happy with the results.”
              </p>
              <div className={`${style.peo} ${style.centerPeo} `}>
                <img
                  // src="https://pbs.twimg.com/profile_images/1257618949665173505/izjTnliK_400x400.jpg"
                  src=""
                  alt=""
                />
                <div>
                  <div className={style.job}>Ian Whiscombe</div>
                  <div>Founder @StockBuddy</div>
                </div>
              </div>
            </Card>
            <br />
            <br />
            <Row gutter={20}>
              <Col span={12}>
                <Card className={style.peopleCard} hoverable>
                  <p>
                    <TwitterOutlined />
                  </p>
                  <p>
                    “Another overpowered project built with the 🪄 OP stack”
                  </p>
                  <div className={style.peo}>
                    <img
                      // src="https://pbs.twimg.com/profile_images/1237045471257952256/Wvh-XjSr_400x400.jpg"
                      src=""
                      alt=""
                    />
                    <div>
                      <div className={style.job}>Greg Kogan</div>
                      <div>VP @pinecone</div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card className={style.peopleCard} hoverable>
                  <p>
                    <TwitterOutlined />
                  </p>
                  <p>“This is awesome, thanks for building it!”</p>
                  <div className={style.peo}>
                    <img
                      // src="https://pbs.twimg.com/profile_images/1379817647139737600/YHL9uBk0_400x400.jpg"
                      src=""
                      alt=""
                    />
                    <div>
                      <div className={style.job}>Logan.GPT</div>
                      <div>Dev Rel @OpenAI</div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Card className={style.peopleCard} hoverable>
              <p>
                <TwitterOutlined />
              </p>
              <p>
                “Website embed works like a charm even with non-English
                content!”
              </p>
              <div className={style.peo}>
                <img
                  // src="https://pbs.twimg.com/profile_images/1475104326930649089/OI1bGmxG_400x400.png"
                  src=""
                  alt=""
                />
                <div>
                  <div className={style.job}>Bence ☺︎ ☻</div>
                  <div>Designer @Nortal</div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div> */}

            {/* <Popover
        placement="top"
        content={<Chat cancel={hide} />}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        overlayClassName="chat-demo"
        style={{
          width: "44%",
        }}
      >
        <FloatButton
          icon={<CommentOutlined />}
          style={{
            bottom: "100px",
          }}
        />
      </Popover> */}
        </div>
    );
}
