import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { Button } from "antd";
import styles from "./style.module.scss";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <div className={styles.icons}>
          <div>SpeciChatbot</div>
          <div></div>
        </div>
        <p>
          <a href="/privace">Privacy Policy</a>
        </p>
        <p>
          <a href="/service">Terms of Service</a>
        </p>
      </div>

      <p>
        Contact:<a href="mailto:support@speci-chatbot.com"> support@speci-chatbot.com</a>
      </p>
    </footer>
  );
}
