import React, { useState } from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input, message } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { get, post } from "../../utils/ajax";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const [type, setType] = useState(1);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
    location.reload();
  };

  const cancelSub = () => {
    get(
      "/sub/cancel-subscription?subscription_id=" + user.subProduct.product_id,
      {},
      {
        token: user.token,
      }
    ).then((res) => {
      console.log(res);
    });
  };

  return (
    <div className={style.wrap}>
      <div className={style.center}>
        <h1>Account</h1>

        <Card title="Your Plan" bordered headStyle={{ fontSize: "26px" }}>
          <h2>You are on the {user.subProduct.product_name} plan</h2>
          <p>{user.subProduct.message_number} message credits/month</p>
          <p>{user.subProduct.chatbot_number} chatbot</p>
          <p>{user.subProduct.token_number} characters/chatbot</p>
          <p>Embed on unlimited websites</p>
          <p>Upload multiple files</p>
          {user.subProduct.product_name == "free" && (
            <p>Chatbots get deleted after 7 days of inactivity</p>
          )}
          <br />
          <div className={style.btn}>
            <Button
              size="large"
              style={{ background: "#000", color: "#fff" }}
              onClick={() => {
                if ((user.subProduct.product_name = "free")) {
                  navigate("/price");
                } else {
                  cancelSub();
                }
              }}
            >
              {
                (user.subProduct.product_name = "free"
                  ? "Upgrade plan"
                  : "unsubscribe")
              }
            </Button>
          </div>
        </Card>
        <br />
        {/* <Card title="Usage">
          <p>Messages consumed: 0/30</p>
          <p>
            Your credits renew at the start of every calendar month. Next
            renewal: 八月 1st
          </p>
        </Card>
        <br /> */}
        <Card title="Your Email" bordered headStyle={{ fontSize: "26px" }}>
          <h2>{user.email}</h2>
          <h2>Name : {user.user_name}</h2>
        </Card>
        <br />
        {/* <Card title="Chatbase API Key">
          <p>Subscription required to get an API key.</p>
        </Card>
        <br /> */}
        <p style={{ textAlign: "center" }}>
          <Button
            onClick={logout}
            size="large"
            style={{ background: "#000", color: "#fff" }}
          >
            Sign out
          </Button>
        </p>
      </div>
    </div>
  );
}
