import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input, message } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { get, post } from "../../utils/ajax";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Index() {
  const [type, setType] = useState(1);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  let [searchParams, setSearchParams] = useSearchParams();
  let checkoutSessionId = searchParams.get("session_id");

  const goto = () => {
    localStorage.clear();
    navigate("/signin");
    location.reload()
  };

  const getList = () => {
    if (!user.token || !checkoutSessionId) {
      navigate("/signin");
    }
    post(
      "/sub/pay_success",
      { checkoutSessionId },
      {
        token: user.token,
      }
    ).then((res) => {
      console.log("res -> :", res);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={style.wrap}>
      <div className={style.center}>
        {/* <h1>Pay Info</h1> */}

        <h1>Pay Success</h1>
        <br />
        <p>
          The payment has been made successfully. Please log out of the current
          account and log in again to start a pleasant AI journey.{" "}
        </p>
        <br />
        <p style={{ textAlign: "center" }}>
          <Button
            onClick={goto}
            size="large"
            style={{ background: "#000", color: "#fff" }}
          >
            Go to Signin
          </Button>
        </p>
      </div>
    </div>
  );
}
