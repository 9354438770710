import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import styles from "./style.module.scss";
import logo from "./../../assets/logo.png";

export default function Header() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <header className={styles.header}>
      <div className={`logo ${styles.mytop}`}>
        <img src={logo} alt="" />
        SpeciChatbot
      </div>

      <ul className={`${styles.nav}  hidden-element `}>
        <li>
          <Link to="/">Demo</Link>
        </li>
        <li>
          <Link to="/price">Pricing</Link>
        </li>
        <li>
          <Link to="/guide">Guide</Link>
        </li>
        <li>
          <Link to="/my-chatbots">My Chatbots</Link>
        </li>
      </ul>

      <div>
        <Dropdown
          className="hidden-menu"
          menu={{
            items: [
              {
                key: "1",
                label: <Link to="/">Demo</Link>,
              },
              {
                key: "2",
                label: <Link to="/price">Pricing</Link>,
              },
              {
                key: "3",
                label: <Link to="/guide">Guide</Link>,
              },
              {
                key: "4",
                label: <Link to="/my-chatbots">My Chatbots</Link>,
              },
              {
                key: "5",
                label: user.token ? (
                  <Link to="/account">Account</Link>
                ) : (
                  <Link to="/signin">Log in</Link>
                ),
              },
            ],
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MenuOutlined />
          </a>
        </Dropdown>

        <div className="hidden-element">
          {user.token ? (
            <Link to="/account">
              Account <span aria-hidden="true">→</span>
            </Link>
          ) : (
            <Link to="/signin">
              Log in <span aria-hidden="true">→</span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
