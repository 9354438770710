import { Routes, Route, Outlet, Link, Switch } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Chat from "./components/Chat";
import { FloatButton, Modal, Popover } from "antd";
import { CommentOutlined, CustomerServiceOutlined } from "@ant-design/icons";

import React from "react";
import Demo from "./pages/Demo";
import Signin from "./pages/Signin";
import Price from "./pages/Price";
import Guide from "./pages/Guide";
import Account from "./pages/Account";
import MyChatbots from "./pages/MyChatbots";
import ChatbotInfo from "./pages/ChatbotInfo";
import CreateChatbot from "./pages/CreateChatbot";
import OutChat from "./pages/OutChat";
import PaySuccess from "./pages/PaySuccess";
import Privace from "./pages/Privace";
import Service from "./pages/Service";
import Response from "./pages/Guide/components/Response";
import Started from "./pages/Guide/components/Started";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Demo />} />
        <Route path="price" element={<Price />} />
        <Route path="signin" element={<Signin />} />
        <Route path="guide" element={<Guide />} />
        <Route path="started" element={<Started />} />
        <Route path="response" element={<Response />} />
        <Route path="account" element={<Account />} />
        <Route path="my-chatbots" element={<MyChatbots />} />
        <Route path="chatbot/:id" element={<ChatbotInfo />} />
        <Route path="create-new-chatbot" element={<CreateChatbot />} />
        <Route path="pay-success" element={<PaySuccess />} />
        <Route path="privace" element={<Privace />} />
        <Route path="service" element={<Service />} />
        {/* <Route path="*" element={<NoMatch />} /> */}
      </Route>
      <Route path="out/:ids" element={<OutChat />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

function Layout() {
  const [open, setOpen] = React.useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <>
      <Header />

      <div className="content sub-content-padding">
        <Outlet />
      </div>

      <Footer />

      <Popover
        placement="top"
        content={<Chat cancel={hide} />}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        overlayClassName="chat-demo"
        style={{
          width: "44%",
        }}
      >
        <FloatButton
          icon={<CommentOutlined />}
          style={{
            bottom: "100px",
          }}
        />
      </Popover>
      <FloatButton.BackTop visibilityHeight={50} />
    </>
  );
}

function NoMatch() {
  return (
    <>
      <h2>Nothing to see here!</h2>
    </>
  );
}
