import React from "react";
import Chat from "./../../components/Chat";
import { useNavigate, useParams } from "react-router-dom";

export default function index() {
  const params = useParams();
  const ids = decodeURIComponent(params?.ids || "");
  const user_id = ids.split(",")[0];
  const chatbot_id = ids.split(",")[1];

  return (
    <>
      <Chat user_id={user_id} chatbot_id={chatbot_id} />
    </>
  );
}
