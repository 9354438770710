import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { get, post } from "./../../utils/ajax";

export default function Index() {
  const [list, setList] = useState([]);

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const getList = () => {
    get("/sub/get_products")
      .then((res) => {
        setList(res.products || []);
      })
      .catch((err) => {
        console.log("err -> :", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const buyGoods = (item) => {
    // 4242424242424242
    get(
      "/sub/create_checkout_session",
      {
        priceId: item.priceId,
      },
      {
        token: user.token,
      }
    ).then((res) => {
      if (res.status == "success") {
        window.location.href = res.pay_url;
      }
    });
  };

  return (
    <div className={style.wrap}>
      <h1>Pricing Plans</h1>

      <Row gutter={[20, 18]}>
        {list.map((item, index) => (
          <Col lg={6} md={12} xs={24} sm={24} key={index + item.product_id}>
            <div className={style.textWrap}>
              <div className={style.textList}>
                <h2>{item.product_name}</h2>
                <p>{item.message_number} message credits/month</p>
                <p>{item.chatbot_number} chatbot</p>
                <p>{item.token_number} characters/chatbot</p>
                <p>Embed on unlimited websites</p>
                <p>Upload multiple files</p>
                {item.product_name == "free" && (
                  <p>Chatbots get deleted after 7 days of inactivity</p>
                )}
              </div>
              <div>
                <div className={style.price}>
                  <h3>${item.price}</h3>
                  <span>
                    /{item.product_name.includes("year") ? "year" : "month"}
                  </span>
                </div>
                <Button
                  size="large"
                  style={{ background: "#000", color: "#fff" }}
                  block
                  disabled={item.product_name == "free"}
                  onClick={() => {
                    buyGoods(item);
                  }}
                >
                  {item.product_name == "free" ? "Signed In" : "Subscribe"}
                </Button>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
