import React from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { Routes, Route, Outlet, Link } from "react-router-dom";

export default function Index() {
  return (
    <div className={style.wrap}>
      <div className={style.center}>
        <h1>Privacy Policy</h1>

        <div>

          <div>
            <p>1. Introduction</p>
            <div>
              Please read this Privacy Policy (“Privacy Policy”) before using
              our services (including websites, widgets, and API (defined
              below)) collectively referred to as “Services”. This Privacy
              Policy governs the types of information and data we collect and
              how we use and share this information. You can access and use this
              Service only when you agree to the terms of service provided at
              the following address: https://www.speci-chatbot.com/terms (“Terms
              of Service”) which includes the terms of this Privacy Policy.
              speci-chatbot.com (“Studio”) operates this Service. We use your
              data to provide and improve the Service. Using the Service
              indicates that you consent to the collection and use of
              information according to this policy. Unless otherwise defined in
              this Privacy Policy, the terms used in this Privacy Policy have
              the same meanings as in our Terms of Service.
            </div>
          </div>
          
          <div>
            <p>2. Definitions</p>
            <div>
              <p>
                API refers to the speci-chatbot application programming
                interface integrated with user software.
              </p>
              <p>Cookie is a small file stored on your device.</p>
              <p>Device refers to a computer or mobile device.</p>
              <p>
                Data Controller refers to the natural or legal person who (alone
                or jointly or in common with other persons) determines the
                purposes and means of processing any personal data. For this
                Privacy Policy, we are the Data Controller of your data.
              </p>

              <p>
                Data Processor (or Service Provider) is any natural or legal
                person processing data on behalf of the Data Controller. We
                might use various Service Providers to process your data more
                efficiently.
              </p>

              <p>
                Data Subject is any individual who is the subject of Personal
                Data.
              </p>
              <p>
                Personal Data refers to data about a living individual who can
                be identified from those data (or from those and other
                information either in our possession or likely to come into our
                possession).
              </p>

              <p>
                Service refers to the website, widget, and/or API (depending on
                the specific scope dedicated to the user).
              </p>

              <p>
                Usage Data is data generated automatically either by the use of
                the Service or from the Service infrastructure itself (for
                example, the duration of a page visit).
              </p>

              <p>
                User is an individual using our Service. User corresponds to the
                Data Subject, who is the subject of the Personal Data.
              </p>

              <p>Website refers to web pages located at speci-chatbot.</p>

              <p>
                Widget refers to the chat library widget that can be implemented
                on user websites.
              </p>
            </div>
          </div>

          <div>
            <p>3. Data Controller</p>
            <div>
              <p>The controller of your personal data is: speci-chatbot.com.</p>
            </div>
          </div>

          <div>
            <p>4. Information Collection and Use</p>
            <div>
              <p>
                We collect various types of information for various purposes to
                provide and improve our Service for you.
              </p>
            </div>
          </div>

          <div>
            <p>5. Types of data collected</p>
            <div>
              <p>personal information</p>

              <p>
                While using our Services, we may ask you to provide us with
                certain personally identifiable information ("Personal Data")
                that can be used to contact or identify you, including:
              </p>

              <p>email address,</p>

              <p>first name and last name,</p>

              <p>Cookies and Usage Data.</p>

              <p>usage data</p>

              <p>
                We may also collect information that your browser sends when you
                visit our Services or access the Services through a device
                ("Usage Data"). This Usage Data may include your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique Information and other information. Device identifiers and
                other diagnostic data. When you use a device to access the
                Service, the usage data may include information such as the type
                of device you use, your device unique ID, your device's IP
                address, your operating system, the type of Internet browser you
                use, unique device identifiers and other information and other
                diagnostic data.
              </p>

              <p>Tracking cookie data</p>

              <p>
                We use cookies and similar tracking technologies to track
                activity on our Service and certain information is held by us.
                Cookies are files containing a small amount of data, which may
                include an anonymous unique identifier. Cookies are sent to your
                browser from a website and stored on your device. Other tracking
                technologies, such as beacons, tags, and scripts, are also used
                to collect and track information and to improve and analyze our
                Services. You can instruct your browser to refuse all cookies or
                to indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use certain parts of our
                Services. Examples of cookies we use:
              </p>

              <p>
                Session Cookies: We use session cookies to operate our services,
                Preference Cookies: We use preference cookies to remember your
                preferences and settings, Security Cookies: We use security
                cookies for security purposes, Advertising Cookies: Advertising
                cookies are used to provide you with advertisements that may be
                relevant to you and your interests.
              </p>
            </div>
          </div>

          <div>
            <p>6. Use of Data</p>
            <div>
              <p>
                speci-chatbot.com uses the personal data collected for various
                purposes:
              </p>

              <p>
                To provide and maintain our service; Personal Data Types: Email
                address, first name and last name, cookies and usage data;
                necessary to fulfill your contractual obligations.
              </p>

              <p>
                To notify you about changes to our service; Personal Data Types:
                Email address, first name and last name, cookies and usage data;
                necessary to fulfill your contractual obligations.
              </p>

              <p>
                To allow you to participate in interactive features of our
                service when you choose to; Personal Data Types: Email address,
                first name and last name, cookies and usage data; necessary to
                fulfill your contractual obligations.
              </p>

              <p>
                To provide customer support; Personal Data Types: Email address,
                first name and last name, cookies and usage data; necessary to
                fulfill your contractual obligations.
              </p>

              <p>
                To gather analysis or valuable information so that we can
                improve our service; Personal Data Types: Email address, first
                name and last name, cookies and usage data; in the legitimate
                interest of the data controller.
              </p>

              <p>
                To monitor the usage of our service; Personal Data Types: Email
                address, first name and last name, cookies and usage data; in
                the legitimate interest of the data controller.
              </p>

              <p>
                To detect, prevent, and address technical issues; Personal Data
                Types: Email address, first name and last name, cookies and
                usage data; in the legitimate interest of the data controller.
              </p>

              <p>
                For any other purpose you provide; Personal Data Types: Email
                address, first name and last name, cookies and usage data;
                necessary to fulfill your contractual obligations.
              </p>

              <p>
                To fulfill our obligations and enforce rights arising from any
                contracts entered into between you and us, including for billing
                and collection; Personal Data Types: Email address, first name
                and last name, cookies and usage data; necessary to fulfill your
                contractual obligations.
              </p>

              <p>
                To provide you with notices about your account and/or
                subscriptions, including expiration and renewal notices, email
                instructions, etc.; Personal Data Types: Email address, first
                name and last name, cookies and usage data; necessary to fulfill
                your contractual obligations.
              </p>

              <p>
                To offer you news, special offers, and general information about
                other goods, services, and events we offer that are similar to
                those you have already purchased or inquired about, unless you
                choose not to receive such information; Personal Data Types:
                Email address, first name and last name, cookies and usage data;
                with your consent.
              </p>

              <p>
                In any other way we may describe when you provide the
                information; Personal Data Types: Email address, first name and
                last name, cookies and usage data; necessary to fulfill your
                contractual obligations.
              </p>
            </div>
          </div>

          <div>
            <p>7. Data Retention</p>
            <div>
              <p>
                We will only retain your personal data for as long as necessary
                for the purposes set out in this privacy policy. We will retain
                and use your personal data to the extent necessary to comply
                with our legal obligations (for example, if we are required to
                retain your data to comply with applicable laws), resolve
                disputes, and enforce our legal agreements and policies. As long
                as the relevant consent is not withdrawn, your personal data
                processed with your consent will be stored until the expiration
                of claims arising from the service. We will also retain usage
                data for internal analysis purposes. Usage data is generally
                retained for a shorter period, unless this data is used to
                enhance security or to improve the functionality of our service,
                or we are legally obligated to retain this data for longer
                periods.
              </p>
            </div>
          </div>

          <div>
            <p>8. Data Transfer</p>
            <div>
              <p>
                Your information, including personal data, may be transferred to
                and stored on computers located outside your state, province,
                country, or other governmental jurisdiction where the data
                protection laws may differ from those in your jurisdiction. If
                you are located outside the United States and choose to provide
                information to us, please note that we transfer the data,
                including personal data, to the United States and process it
                there. The studio will take all steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this privacy policy and no transfer of your personal data will
                be made to an organization or a country unless there are
                adequate controls in place, including security measures,
                regarding your data and other personal information. When we
                transfer your personal data to other countries, we will protect
                that personal data as described in this privacy policy and in
                accordance with applicable law. We use contractual protection
                measures for transferring personal data between different
                jurisdictions, as mentioned in Article 46.2(c) of the GDPR,
                referring to the standard contractual clauses of the European
                Commission.
              </p>
            </div>
          </div>

          <div>
            <p>9. Data Disclosure</p>
            <div>
              <p>We may disclose the personal data you provide:</p>

              <p>
                In certain circumstances, if required by law or in response to
                valid requests by public authorities.
              </p>

              <p>
                If we or our subsidiaries are involved in a merger, acquisition,
                or asset sale.
              </p>

              <p>To our subsidiaries, affiliates, employees.</p>

              <p>
                To contractors, service providers, and other third parties we
                use to support our business.
              </p>
              <p>For the purpose for which you provided the information.</p>
              <p>Toinclude your studio's logo on our website.</p>

              <p>In any other situation with your consent.</p>

              <p>
                Apart from what is described in this privacy policy, we will not
                sell or otherwise share your personal data.
              </p>
            </div>
          </div>

          <div>
            <p>10. Data Security</p>
            <div>
              <p>
                The security of your data is important to us, but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. We use appropriate
                administrative, technical, and physical safeguards to protect
                the personal data you provide from accidental, unlawful, or
                unauthorized destruction, loss, alteration, access, disclosure,
                or use. For instance, we maintain backup copies, and only
                authorized personnel can access personal data.
              </p>
            </div>
          </div>

          <div>
            <p>
              11. Your Data Protection Rights Under the General Data Protection
              Regulation (GDPR)
            </p>
            <div>
              <p>
                If you are a resident of the European Union (EU) and the
                European Economic Area (EEA), you have certain data protection
                rights covered by the GDPR. Our goal is to take reasonable steps
                to allow you to correct, amend, delete, or limit the use of your
                personal data. You have the following data protection rights:
              </p>

              <p>
                To access your personal data by requesting to share and/or send
                copies of all personal data we process.
              </p>
              <p>
                To request correction of inaccurate personal data by specifying
                the data that needs correction.
              </p>
              <p>
                To request deletion of your personal data; we have the right to
                refuse the deletion of personal data in specific situations
                stipulated by law.
              </p>
              <p>
                To request restriction on processing your personal data by
                specifying the data that should be limited.
              </p>

              <p>
                To object to the processing of your personal data for reasons
                related to your particular situation.
              </p>
              <p>
                To withdraw consent for processing your personal data at any
                time. Consent can only be withdrawn within the scope of
                consent-based processing. If we have a legal basis for such
                processing, we have the right to process your personal data
                after withdrawing your consent, for purposes stipulated by that
                legal basis.
              </p>
              <p>
                If you believe that the processing of your personal data related
                to you violates the GDPR, you have the right to lodge a
                complaint with a supervisory authority, especially in the EU
                member state of your habitual residence, place of work, or place
                of alleged infringement.
              </p>
              <p>
                If you wish to exercise any of the above rights, please send an
                email to support@speci-chatbot.com. Please note that we may ask
                you to verify your identity before responding to such requests.
                Please be aware that, without some necessary data, we might not
                be able to provide services.
              </p>
            </div>
          </div>

          <div>
            <p>
              12. Your Data Protection Rights Under the California Online
              Privacy Protection Act (CalOPPA)
            </p>
            <div>
              <p>
                {" "}
                CalOPPA is the first state law in the U.S. that requires
                commercial websites and online services to post a privacy
                policy. The law's reach stretches well beyond California,
                requiring any person or studio in the United States (and
                conceivably the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website, stating
                exactly the information being collected and those individuals
                with whom it is being shared, and to comply with this policy.
                For more information, see:
                https://consumercal.org/about-cfc/cfc-education-foundation/
              </p>
              <p>Under CalOPPA, we agree to the following:</p>
              <p>Users can visit our site anonymously.</p>

              <p>
                Our privacy policy link includes the word 'Privacy' and can be
                easily found on the page specified on our website's homepage.
              </p>
              <p>
                Users will be notified of any changes to our privacy policy on
                our privacy policy page.
              </p>
              <p>
                Users can change their personal information by emailing us at
                support@speci-chatbot.com.
              </p>
              <p>Our policy on "Do Not Track" signals:</p>
              <p>
                When a "Do Not Track" browser mechanism is in place, we respect
                "Do Not Track" signals and do not track, plant cookies, or use
                advertising. "Do Not Track" is a preference you can set in your
                web browser to inform websites that you do not want to be
                tracked. You can enable or disable "Do Not Track" by visiting
                the "Preferences" or "Settings" page of your web browser.{" "}
              </p>
            </div>
          </div>

          <div>
            <p>13. Service Providers</p>
            <div>
              <p>
                We may employ third-party companies and individuals to
                facilitate our service ("Service Providers"), to provide the
                service on our behalf, perform service-related services, or
                assist us in analyzing how our service is used. These third
                parties have access to your personal data only to perform these
                tasks on our behalf and are obligated not to disclose or use it
                for any other purpose.
              </p>
            </div>
          </div>

          <div>
            <p>14. Analytics</p>
            <div>
              <p>
                We may use third-party service providers to monitor and analyze
                the usage of our service.
              </p>

              <p>Google Analytics</p>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our service. This data
                is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network. For more information on Google's
                privacy practices, please visit the Google Privacy & Terms web
                page: https://policies.google.com/privacy?hl=en. We also
                encourage you to review Google's policy for safeguarding your
                data: https://support.google.com/analytics/answer/6004245.
              </p>

              <p>Mixpanel</p>
              <p>
                Mixpanel is provided by Mixpanel Inc. You can prevent Mixpanel
                from using your information for analytics purposes by opting
                out. To opt-out of Mixpanel service, please visit this page:
                https://mixpanel.com/optout/. For more information on what type
                of information Mixpanel collects, visit the Terms of Use page of
                Mixpanel: https://mixpanel.com/terms/.
              </p>
            </div>
          </div>

          <div>
            <p>15. Payments</p>
            <div>
              <p>
                We may provide paid products and/or services within the service.
                In that case, we use third-party services for payment processing
                (e.g., payment processors). We will not store or collect your
                payment card details. That information is provided directly to
                our third-party payment processors, whose use of your personal
                information is governed by their privacy policy. These payment
                processors adhere to the standards set by PCI-DSS as managed by
                the PCI Security Standards Council, a joint effort of brands
                like Visa, Mastercard, American Express, and Discover. PCI-DSS
                requirements help ensure the secure handling of payment
                information. Payment processors we cooperate with include:
              </p>
            </div>
          </div>

          <div>
            <p>16. Links to Other Sites</p>
            <div>
              <p>
                Our service may contain links to other sites not operated by us.
                If you click on a third-party link, you will be directed to that
                third party's site. We strongly advise you to review the privacy
                policy of every site you visit. We have no control over and
                assume no responsibility for the content, privacy policies, or
                practices of any third-party sites or services.
              </p>
            </div>
          </div>

          <div>
            <p>17. Children’s Privacy</p>
            <div>
              <p>
                Our Services are not directed to anyone under the age of 16
                (“Children”). We do not knowingly collect personally
                identifiable information from anyone under the age of 16. If you
                are a parent or guardian and you are aware that your child has
                provided us with Personal Data, please contact us. If we
                discover that we have collected a child's personal data without
                parental consent, we will take steps to delete that information
                from our servers.
              </p>
            </div>
          </div>

          <div>
            <p>18. Changes to This Privacy Policy</p>
            <div>
              <p>
                We may update our Privacy Policy from time to time. Unless
                otherwise required by applicable law, we will inform you of any
                changes by posting the new Privacy Policy on this page and
                updating the "effective date" at the top of this page. It is
                recommended that you review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page. If you continue to use our
                services or provide us with personal data after we post an
                updated Privacy Policy or notify you (if applicable), it means
                you agree to the revised Privacy Policy and the practices
                described therein.
              </p>
            </div>
          </div>

          <div>
            <p>19. Contact Us</p>
            <div>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us at support@speci-chatbot.com.
              </p>
            </div>
          </div>

          <div>
            <p>20. Privacy Policy Appendices</p>
            <div>
              <p>Canadian Privacy Policy Appendix</p>
              <p>
                The personal data that we maintain and process through our
                subsidiaries and third-party service providers in the US and
                other foreign jurisdictions may be disclosed pursuant to lawful
                access requests from US or foreign courts or government
                agencies. Without your prior consent, we will not provide your
                information to third parties for marketing purposes. For more
                information about our privacy practices; to access, update, or
                correct inaccuracies in your personal information; or if you
                have questions or complaints about how we or our service
                providers handle your personal information, please contact us
                using the data specified in the "Contact Us" section above.
              </p>
              <p>Mexican Privacy Policy Appendix</p>
              <p>
                Within the scope of Mexican privacy laws or regulations, the
                following Mexico-specific additional terms apply and take
                precedence over conflicting terms in the Privacy Policy.
              </p>
              <p>How We Use the Information We Obtain</p>
              <p>
                In addition, we may use your personal data for other purposes
                listed in the "Data Usage" section of the Privacy Policy, which,
                according to Mexican law, may be considered secondary purposes.
                Unless related to payment processing or backed by another legal
                basis, we will not use or share personal payment or financial
                information. You can contact us following the instructions in
                the "How to Contact Us" section of the Privacy Policy to
                withdraw your consent for us to process your personal data. If
                you withdraw your consent for us to process your personal data
                for one of the primary purposes mentioned, we may, in some
                cases, be unable to provide services, but we may still perform
                certain processing authorized by law. The studio may also use
                data and related data analysis in other studio products and
                services.
              </p>
              <p>The Information We Share</p>
              <p>
                We may share your personal data as provided in the Privacy
                Policy, but within the limits allowed by law, you can contact us
                following the instructions in the "How to Contact Us" section of
                the Privacy Policy to withdraw your consent to such sharing. We
                also share relevant information we obtain with our customers,
                partners, affiliates, and co-marketing partners. These entities
                (collectively referred to here as "business partners") can use
                this information for the purposes described in this Privacy
                Policy. We may also share information with our business partners
                and other third parties for warranty, troubleshooting, or
                maintenance purposes, or to improve the design and performance
                of their products and services.
              </p>
              <p>
                Requests to Access, Correct, Cancel, Oppose, or Withdraw Consent
              </p>
              <p>
                In addition to any rights granted in the Privacy Policy, you can
                contact us following the instructions in the "How to Contact Us"
                section of the Privacy Policy to request that we cancel your
                personal data to the extent permitted by law. After submitting a
                valid cancellation request, we may retain personal data for the
                duration and purposes allowed by law before deleting it. We will
                respond within 20 working days of receiving your complete
                request, or as required by law, to your request to access,
                correct, or cancel your personal data, or to oppose or withdraw
                your consent for us to process your personal data. To be
                considered complete, your request must include your full name
                and contact address and a clear, detailed description of your
                request.
              </p>

              <p>Japanese Privacy Policy Appendix</p>
              <p>
                We comply with the Personal Information Protection Act and other
                Japanese laws and regulations. The studio primarily manages
                personal data jointly used with our subsidiaries or third
                parties. Without your prior consent, we will not provide your
                information to third parties for marketing purposes.
              </p>
              <p>Korean Privacy Policy Appendix</p>
              <p>
                Unless otherwise provided by law, consumer personal data will be
                immediately and safely disposed of in the following situations:
                (i) the consumer revokes their consent for us to use the
                information, (ii) the purpose for which we collect and use
                personal data has changed or been achieved, or (iii) the
                statutory retention period has expired. If applicable law
                requires the retention of personal data that would otherwise be
                disposed of, the personal data will be transferred to a separate
                database and then disposed of after the period determined by
                applicable law. The method of processing personal data should
                reasonably prevent the personal data from being restored or
                reused. We will not provide your personal data to third parties
                in violation of the law (e.g., without your consent where
                consent is required). For the purposes specified in the Privacy
                Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
