import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input, message } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { get, post } from "../../utils/ajax";
import { useNavigate, Link } from "react-router-dom";

export default function Index() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const getList = () => {
    if (!user.token) {
      navigate("/signin");
    }
    post(
      "/chats_bp/get_all_chatbots",
      {},
      {
        token: user.token,
      }
    ).then((res) => {
      setList(res.chatbots || []);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={`${style.wrap} `}>
      <div className={`${style.center} sub-center-padding`}>
        <div className={style.top}>
          <h1>My Chatbots</h1>
          <p>({user?.subProduct?.chatbot_number || 0} chatbots limit)</p>
        </div>
        <div>
          <Row gutter={[16, 16]} className={style.cards}>
            {list.map((item, index) => (
              <Col key={index} lg={6} md={12} xs={24} sm={24}>
                <Link to={`/chatbot/${item.chatbot_id}`}>
                  <Card
                    style={{
                      width: 180,
                    }}
                    className={style.card}
                    cover={
                      <img
                        alt="example"
                        src="https://backend.chatbase.co/storage/v1/object/public/chatbase/chatbot-placeholder.png?width=640&quality=50"
                      />
                    }
                  >
                    <Card.Meta title={item.name} />
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>

          <p style={{ textAlign: "center" }}>
            <Button
              size="large"
              href="/create-new-chatbot"
              style={{ background: "#000", color: "#fff" }}
            >
              New Chatbot
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}
