import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Button, Tabs, Upload, Card, Form, Input, message, Modal } from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { getFile, post, get } from "../../utils/ajax";
import { useNavigate, useParams } from "react-router-dom";
import Chat from "./../../components/Chat";
import CreateChatbot from "./../CreateChatbot";

export default function Index() {
  const [type, setType] = useState("Chatbot");
  const navigate = useNavigate();
  const params = useParams();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [info, setInfo] = useState({});

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const getList = () => {
    if (!user.token) {
      navigate("/signin");
    }
    get(
      "/chats_bp/chatbot_info",
      { chatbot_id: params.id },
      {
        token: user.token,
      }
    ).then((res) => {
      if (res) {
        console.log("res -> :", res);
        res.files = (res.files || []).map((item) => ({
          ...item,
          status: "done",
          uid: item.file_id + item.knowledge_id + item.filename,
          url: item.filename + item.file_id,
          name: item.filename,
        }));
        setInfo(res);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const del = (chatbot_id) => {
    post(
      "/chats_bp/delete",
      { chatbot_id },
      {
        token: user.token,
      }
    ).then((res) => {
      navigate("/my-chatbots");
    });
  };

  const download = (chatbot_id) => {
    getFile(
      "/chats_bp/download",
      { chatbot_id },
      {
        token: user.token,
      }
    ).then((res) => {
      console.log("res -> :", res);
    });
  };

  const items = [
    {
      key: "Chatbot",
      label: `Chatbot`,
      children: (
        <>
          <Chat chatbot_id={params.id} />
        </>
      ),
    },

    {
      key: "Dashboard",
      label: `Dashboard`,
      children: (
        <>
          <p style={{ textAlign: "center" }}>
            <Button
              size="large"
              style={{ background: "#000", color: "#fff" }}
              onClick={() => download(params.id)}
            >
              Export filtered conversations
            </Button>
          </p>
        </>
      ),
    },
    {
      key: "Sources",
      label: `Sources`,
      children: (
        <>
          <CreateChatbot info={info} refesh={getList} />
        </>
      ),
    },
    {
      key: "open",
      label: `Embed on website`,
      children: "",
    },
    {
      key: "Delete",
      label: `Delete`,
      children: "",
    },
  ];

  const onChange = (key) => {
    if (key == "Delete") {
      Modal.confirm({
        title: "Do you Want to delete these items?",
        icon: <ExclamationCircleFilled />,
        content: "",
        onOk() {
          del(params.id);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
      return;
    }

    if (key == "open") {
      const ids = encodeURIComponent(`${user.user_id},${params.id}`);
      const src = `${window.location.origin}/out/${ids}`;
      Modal.info({
        title: "Embed on website",
        icon: <></>,
        footer: null,
        maskClosable: true,
        onCancel() {
          console.log("Cancel");
        },

        width: "620px",
        content: (
          <>
            To add the chatbot any where on your website, add this iframe to
            your html code
            <br />
            <br />
            <div>
              <pre style={{ background: "#eee", padding: "12px" }}>
                <code style={{ whiteSpace: "normal" }}>
                  {`
                  <iframe 
                    src="${src}" 
                    style="height: 100%; min-height: 700px" 
                    frameborder="0" 
                  >
                  </iframe>
                  `}
                </code>
              </pre>
            </div>
          </>
        ),
      });

      return;
    }

    setType(key);
  };

  return (
    <div className={style.wrap}>
      <div className={style.center}>
        <h1>{info.name}</h1>
        <Tabs
          centered
          destroyInactiveTabPane
          activeKey={type}
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
