import React from "react";
import style from "./style.module.scss";
import { Button, Row, Col, Card, Form, Input } from "antd";
import { TwitterOutlined } from "@ant-design/icons";
import { Routes, Route, Outlet, Link } from "react-router-dom";

export default function Index() {
  return (
    <div className={style.wrap}>
      <div className={style.center}>
        <h1>Terms of Services</h1>
        <span>Last modified: September 22, 2023</span>

        <div>
          <div>
            <p>1. Introduction</p>
            <div>
              These terms of service (“Terms”, “Terms of Service”) are used to
              determine the rules of the service and clarify the legal
              relationship between the entity using the service and the studio.
              SpeciChatbot (“Studio”) operates the web page located at
              speci-chatbot.com (“Website”) and provides services (defined in
              section 2 below). This service aims to assist professional
              entities in conducting business. By using this website and/or
              service in any way, or by going through the account registration
              process, the user or visitor declares that (i) they have read,
              understood, and accepted the provisions of the Terms and the
              Privacy Policy, (ii) they are of legal age and capacity to enter
              into an agreement with the studio, (iii) they have the right to
              act on behalf of the entity they represent, and their declaration
              is valid. If the user does not agree to apply according to the
              provisions of this term, they should not use this service.
              However, in this case, users can contact the studio by sending an
              email to support@speci-chatbot.com to try to find a solution.
            </div>
          </div>

          <div>
            <p>2. Definitions</p>
            <div>
              <p>
                Unless otherwise specified, the following capitalized terms have
                the following meanings:
              </p>

              <p>
                Account – Refers to the personalized panel provided to users to
                benefit from the service and/or website.
              </p>
              <p>
                Agreement – Refers to the subscription agreement that might be
                signed between the user and the studio, specifying the scope and
                price of using the service.
              </p>
              <p>
                API – Refers to the SpeciChatbot application programming
                interface integrated with user software.
              </p>
              <p>Studio – Refers to speci-chatbot.com.</p>
              <p>
                Content – Refers to texts, images, etc., posted, presented, or
                sent by visitors or users on the website or using the service.
              </p>
              <p>
                Device – Refers to devices like computers, phones, smartphones,
                tablets, or other electronic devices that allow web browsing.
              </p>
              <p>Visitor – Refers to entities browsing this website.</p>
              <p>
                Intellectual Property – Refers to any name, invention, utility
                model, industrial design, work, and other creative activities'
                external expression that belongs to the studio's proprietary
                rights.
              </p>
              <p>
                License – Refers to the non-exclusive right for individual use
                of the API and/or Widget.
              </p>
              <p>Link – Refers to hyperlinks pointing to other websites.</p>
              <p>
                Mobile Device – Refers to portable devices, such as phones,
                smartphones, tablets, or other electronic devices, that run the
                website and/or service.
              </p>
              <p>
                Notification – Refers to messages sent to users as part of the
                service.
              </p>
              <p>
                Other Websites – Refers to websites other than this website.
              </p>
              <p>
                Password – Refers to the user's anonymous authentication tool,
                which can be used to access the account and is a sequence of
                symbols that can be input using a computer or mobile device
                keyboard. It also allows for the input of text messages invented
                by the user but contains at least 8 symbols.
              </p>
              <p>
                Privacy Policy – Refers to the privacy policy that defines the
                rules by which the studio handles personal data, available at:{" "}
                <a href="https://www.speci-chatbot.com/privace" target="_blank">
                  https://www.speci-chatbot.com/privace
                </a>
              </p>
              <p>
                Service – Refers to the website, widget, and/or API (depending
                on the scope dedicated to a particular user according to the
                agreement).
              </p>
              <p>
                Terms – Refers to these terms of service, available at:{" "}
                <a href="https://www.speci-chatbot.com/service" target="_blank">
                  https://www.speci-chatbot.com/service
                </a>
              </p>
              <p>User – Refers to entities with an account.</p>
              <p>
                Website – Refers to the web page located at speci-chatbot.com.
              </p>
              <p>
                Widget – Refers to the chat library widget that can be
                implemented on user websites.
              </p>
              <p>
                The above definitions retain their meaning whether expressed in
                singular or plural.
              </p>
            </div>
          </div>

          <div>
            <p>3. General Rules</p>
            <div>
              <p>
                Using the service means fully accepting the terms. These terms
                only apply to the relationship between the studio and guests or
                users. If the service and/or website introduces the possibility
                of services provided by other entities, the services will be
                provided according to the regulations shared by these entities,
                and only these entities are responsible for executing the
                service, except for the content of the service. The terms
                explicitly indicate other situations. Every entity using the
                service and/or website has an obligation to use the service
                and/or website for the purposes specified in the terms.
              </p>
              <p>
                The use of this website is permitted if the device meets the
                following minimum technical requirements:
              </p>

              <p>Can access the internet</p>
              <p>
                Allows launching from the following internet browsers: (i)
                Google Chrome, (ii) Opera, (iii) Firefox, (iv) Safari, (v)
                Microsoft Edge
              </p>
              <p>
                The version of the internet browser used should not be more than
                a year old
              </p>
              <p>JavaScript has been enabled</p>
              <p>
                The API can be used if the following minimum technical
                requirements are met:
              </p>
              <p>Can access the internet</p>
              <p>Has software capable of sending HTTP requests</p>
              <p>
                The widget can be used if the following minimum technical
                requirements are met:
              </p>
              <p>Can access the internet</p>
              <p>
                Allows launching from the following internet browsers: (i)
                Google Chrome, (ii) Opera, (iii) Firefox, (iv) Safari, (v)
                Microsoft Edge
              </p>
              <p>
                The version of the internet browser used should not be more than
                a year old
              </p>
              <p>JavaScript has been enabled</p>
              <p>
                It is recommended to install antivirus software on the device or
                mobile device.
              </p>
            </div>
          </div>

          <div>
            <p>4. Account</p>
            <div>
              <p>
                Accounts can be set up through the website. Setting up an
                account requires logging in using a Facebook account, Google
                account, or registering by providing other personal emails and
                passwords. When you create an account, you should always provide
                us with accurate, complete, and up-to-date information.
                Inaccurate, incomplete, or outdated information may lead to the
                immediate termination of your account on the website and
                service. You are responsible for maintaining the confidentiality
                of your account and password, including but not limited to
                restricting access to your device or mobile device and/or
                account. You agree to be responsible for any and all activities
                or actions that occur under your account and/or password,
                whether your password is used for our website and services or
                third-party services. You must immediately notify us of any
                security breaches or unauthorized use of your account. Without
                proper authorization, you may not use the name or trademark of
                another person or entity as a username, which name or trademark
                is bound by the rights of any other person or entity other than
                you. You may not use a username that is offensive, vulgar, or
                obscene.
              </p>
            </div>
          </div>

          <div>
            <p>5. Communication</p>
            <div>
              <p>
                By creating an account, you can agree to subscribe to
                newsletters, marketing or promotional materials, and other
                information we may send.
              </p>
            </div>
          </div>

          <div>
            <p>6. Contests, Raffles, and Promotions</p>
            <div>
              <p>
                Any contests, raffles, or other promotional activities
                (collectively referred to as "promotional activities") offered
                through the service or website may be subject to rules separate
                from these terms (especially rules stipulated in the agreement
                if reached). If you participate in any promotional activities,
                please review the applicable rules and our privacy policy. If
                the promotional rules conflict with these terms, the promotional
                rules will prevail.
              </p>
            </div>
          </div>

          <div>
            <p>7. Subscription</p>
            <div>
              <p>
                Some parts of the service are billed on a subscription basis
                (“Subscription”). We will regularly charge you an advance fee
                (“Billing Cycle”). Billing cycles are set monthly or annually,
                depending on the type of subscription plan you choose when
                purchasing the subscription. At the end of each billing cycle,
                your subscription will automatically renew under the exact same
                conditions unless you cancel the subscription or the studio
                cancels it. You can cancel subscription renewals through your
                account or by contacting the studio's customer support team at
                support@speci-chatbot.com. A valid payment method (including
                credit or debit cards, Apple Pay, or Google Pay) is required to
                process your subscription payment. You should provide the studio
                with accurate, complete billing information, including (i) full
                name, (ii) email address, (iii) country/region, (iv) corporate
                legal entity name, (v) VAT number, and (vi) valid payment method
                information. By submitting such payment information, you
                automatically authorize the studio to charge any such payment
                instrument for all subscription fees incurred through your
                account. If for any reason automatic billing fails, the studio
                will issue an electronic invoice indicating that you must pay
                manually by a specific due date and pay the full amount for the
                billing cycle shown on the invoice. We reserve the right to
                refuse or cancel your order at any time for reasons including
                but not limited to: service availability, errors in the
                description or price of the service, errors in your order, or
                other reasons. If there is suspicion of fraud, unauthorized, or
                illegal transactions, we reserve the right to refuse or cancel
                your order. The studio is not responsible for any additional
                fees, including taxes, levied by the authorities of the
                country/region where the order placer resides. Payment of these
                additional fees remains the responsibility of the person placing
                the order. If there is suspicion of fraud, unauthorized, or
                illegal transactions, we reserve the right to refuse or cancel
                your order. The studio is not responsible for any additional
                fees, including taxes, levied by the authorities of the
                country/region where the order placer resides. Payment of these
                additional fees remains the responsibility of the person placing
                the order. If there is suspicion of fraud, unauthorized, or
                illegal transactions, we reserve the right to refuse or cancel
                your order. The studio is not responsible for any additional
                fees, including taxes, levied by the authorities of the
                country/region where the order placer resides. Payment of these
                additional fees remains the responsibility of the person placing
                the order.
              </p>
            </div>
          </div>

          <div>
            <p>8. Fee Changes</p>
            <div>
              <p>
                The studio may, at its sole discretion, modify the fees for the
                subscriptions at any time. Any subscription fee change will take
                effect at the end of the current billing cycle. The studio will
                provide reasonable prior notice of any change in subscription
                fees to give you an opportunity to terminate your subscription
                before such change becomes effective. By continuing to use the
                service after the subscription fee change goes into effect, you
                agree to pay the modified subscription fee amount.
              </p>
            </div>
          </div>

          <div>
            <p>9. Refunds</p>
            <div>
              <p>
                Unless required by law, paid subscription fees are
                non-refundable.
              </p>
            </div>
          </div>

          <div>
            <p>10. Content</p>
            <div>
              <p>
                Our service and website allow you to post, link, store, share,
                and otherwise provide certain information, text, graphics,
                videos, or other materials (“Content”). You are responsible for
                the content you post on or through the service, especially
                towards your clients, including its legality, reliability, and
                appropriateness. By posting content on or through the service or
                website, you represent and warrant that: (i) the content is
                yours (you own it) and/or you have the right to use it and the
                right to grant us the rights and license as provided in these
                terms, and (ii) that the posting of your content on or through
                the service does not violate the privacy rights, publicity
                rights, copyrights, contract rights, or any other rights of any
                person or entity. We reserve the right to terminate any user
                account found to be infringing on copyrights. You retain any and
                all rights to any content you submit, post, or display on or
                through the service or website, and you are responsible for
                protecting those rights. We take no responsibility for any
                content posted by you or any third party on the service or
                through the service.
              </p>
            </div>
          </div>

          <div>
            <p>11. Prohibited Uses</p>
            <div>
              <p>
                You may only use the service or website for lawful purposes and
                in accordance with these terms. You agree not to use the service
                or website to:
              </p>
              <p>
                Violate any applicable national or international law or
                regulation in any way,
              </p>

              <p>
                Exploit, harm, or attempt to exploit or harm minors in any way,
                exposing them to inappropriate content or otherwise,
              </p>

              <p>
                Transmit or promote the sending of any advertising or
                promotional material, including any "junk mail", "chain letter",
                "spam", or any other similar solicitation,
              </p>

              <p>
                Impersonate or attempt to impersonate the studio, studio
                employees, other users, or any other person or entity,
              </p>

              <p>
                Infringe upon the rights of others in any way, or in any way
                that is unlawful, threatening, fraudulent, or harmful, or in
                connection with any unlawful, illicit, fraudulent, or harmful
                purpose or activity,
              </p>

              <p>
                Engage in any other conduct that restricts or inhibits anyone's
                use or enjoyment of the service or website, or which, in our
                judgment, may harm or offend the studio or users of the service
                or website, or expose them to liability.
              </p>

              <p>In addition, you agree not to:</p>

              <p>
                Use the service and website in any manner that could disable,
                overburden, damage, or impair the site or interfere with any
                other party's use of the service, including their ability to
                engage in real-time activities through the service or website,
              </p>
              <p>
                Use any robot, spider, or other automatic device, process, or
                means to access the service or website for any purpose,
                including monitoring or copying any of the material on the
                service or website,
              </p>
              <p>
                Use any manual process to monitor or copy any of the material on
                the service or website or for any other unauthorized purpose
                without our prior written consent,
              </p>
              <p>
                Use any device, software, or routine that interferes with the
                proper working of the service or website,
              </p>
              <p>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful,
              </p>
              <p>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the service or website, the server on
                which the service or website is stored, or any server, computer,
                or database connected to the service or website,
              </p>
              <p>
                Attack the service or website via a denial-of-service attack or
                a distributed denial-of-service attack,
              </p>
              <p>Take any action that may undermine the studio's ratings,</p>
              <p>
                Otherwise attempt to interfere with the proper working of the
                service or website.
              </p>
            </div>
          </div>

          <div>
            <p>12. Analytics</p>
            <div>
              <p>
                We may use third-party service providers to monitor and analyze
                the use of our service and website.
              </p>
              <p>Google Analytics</p>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our service and
                website. This data is shared with other Google services. Google
                may use the collected data to contextualize and personalize the
                ads of its own advertising network. For more information on
                Google's privacy practices, please visit the Google Privacy
                Terms web page: https://policies.google.com/privacy?hl=en. We
                also encourage you to review Google's policy on safeguarding
                your data: https://support.google.com/analytics/answer/6004245.
              </p>
              <p>Mixpanel</p>
              <p>
                Mixpanel is provided by Mixpanel Inc. You can prevent Mixpanel
                from using your information for analytics purposes by opting
                out. To opt-out of Mixpanel service, please visit this page:
                https://mixpanel.com/optout. For more information about what
                type of information Mixpanel collects, please visit the Terms of
                Use of Mixpanel: https://mixpanel.com/terms.
              </p>
            </div>
          </div>

          <div>
            <p>13. Intellectual Property</p>
            <div>
              <p>
                The service, website, and its original content (excluding
                Content), features, and functionality are now and will remain
                the exclusive property of the studio and its licensors. The
                service and website are protected by copyright, trademark, and
                other laws of both the United States and foreign countries. Our
                trademarks and trade dress may not be used in connection with
                any product or service without our prior written consent.
                Visitors and users do not obtain any intellectual property
                rights by using our services. It is prohibited to use
                intellectual property for purposes other than those authorized
                under Article 23 of the Copyright and Related Rights Act of
                February 4, 1994. After purchasing a subscription, the user
                receives a license to use the service according to the
                subscription. This license is limited, non-transferable,
                revocable, non-exclusive, and is granted for the duration
                consistent with the subscription conditions. The user is not
                entitled to grant sub-licenses. The license is granted only in
                the following forms of use:
              </p>

              <p>
                Subscribing and installing the service on the user's website
                and/or software,
              </p>

              <p>
                Using the service to provide services to the user's clients.
              </p>

              <p>
                The user may not modify, reverse engineer, decompile,
                disassemble, or attempt to obtain the source code of the service
                or website or any part thereof. The user is not entitled to
                create and/or publish APIs or otherwise proxy access to the
                service or website, nor may the service or website be used
                automatically (i.e., by machine or computer program).
              </p>
            </div>
          </div>

          <div>
            <p>14. Copyright Policy</p>
            <div>
              <p>
                We respect the intellectual property rights of others. It is our
                policy to respond to any claims of infringement of any
                individual or entity's copyright or other intellectual property
                rights ("Infringement") for content used on the service or
                posted on the website. If you are a copyright owner or an
                authorized representative of a copyright owner, and you believe
                that a copyrighted work has been copied in a way that
                constitutes copyright infringement, please submit your claim via
                email to support@speci-chatbot.com, with the subject line:
                "Copyright Infringement", and include in your claim a detailed
                description of the alleged Infringement as set forth in the
                section "DMCA Notice and Procedure for Copyright Infringement
                Claims" below. You may be held liable for damages (including
                costs and attorneys' fees) for any misrepresentation or
                malicious claim about the content found on and/or through the
                service or website.
              </p>
            </div>
          </div>

          <div>
            <p>
              15. DMCA Notice and Procedure for Copyright Infringement Claims
            </p>
            <div>
              <p>
                You may submit a notification pursuant to the Digital Millennium
                Copyright Act (DMCA) by providing our Copyright Agent with the
                following information in writing (see 17 USC 512(c)(3) for
                further detail):
              </p>
              <p>
                An electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright interest,
              </p>
              <p>
                A description of the copyrighted work that you claim has been
                infringed, including the URL (i.e., web page address) where the
                copyrighted work is located or a copy of the copyrighted work,
              </p>
              <p>
                Identification of the URL or other specific location on the
                service where the material that you claim is infringing is
                located,
              </p>
              <p>Your address, telephone number, and email address,</p>

              <p>
                A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law,
              </p>
              <p>
                A statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner's behalf.
              </p>

              <p>You can con 16. Error Reporting and Feedback</p>
              <p>
                The studio strives to assist guests and users in resolving
                issues related to the operation of the service and takes actions
                to enhance the quality of service operation and the quality of
                service provided. Feedback is submitted by these entities.
              </p>

              <p>
                You can directly provide us with information and feedback
                regarding errors, suggestions for improvements, ideas, problems,
                complaints, and other matters related to our service through
                support@speci-chatbot.com ("Feedback"). You acknowledge and
                agree that: (i) you shall not retain, acquire, or assert any
                intellectual property right or other rights, title, or interest
                in or to the Feedback; (ii) the studio may have development
                ideas similar to the Feedback; (iii) Feedback does not contain
                confidential information or proprietary information from you or
                any third party; (iv) the studio is not under any obligation of
                confidentiality with respect to the Feedback. If the ownership
                of the feedback cannot be transferred due to applicable
                mandatory laws, you grant the studio and its affiliates an
                exclusive, transferable, irrevocable, free, sublicensable,
              </p>
            </div>
          </div>

          <div>
            <p>17. Links to Other Websites</p>
            <div>
              <p>
                Our service and/or website may contain links to other websites
                that are not owned or controlled by the studio. The studio does
                not control the content, privacy policies, or practices of any
                other websites and assumes no responsibility for them. We do not
                guarantee the products of these entities/individuals or other
                websites. You acknowledge and agree that the studio is not
                directly or indirectly responsible or liable for any damage or
                loss caused or alleged to be caused by or in connection with the
                use of or reliance on any such content, goods, or services
                available on or through any such website. We strongly recommend
                that you read the terms of service and privacy policies of any
                other website you visit.
              </p>
            </div>
          </div>

          <div>
            <p>18. Disclaimer</p>
            <div>
              <p>
                The service is provided by the studio on an "as is" and "as
                available" basis. The studio makes no representations or
                warranties of any kind, express or implied, regarding the
                operation of its services or the information, content, or
                materials included therein. You expressly agree to assume the
                risk of using the service, its content, and any services or
                items obtained from us. Neither the studio nor anyone associated
                with the studio makes any warranty or representation about the
                completeness, security, reliability, quality, accuracy, or
                availability of the service. Without limiting the foregoing,
                neither the studio nor anyone associated with the studio
                represents or warrants that the service, its content, or any
                services or items obtained through the service will be accurate,
                reliable, error-free, or uninterrupted, that defects will be
                corrected, that the service or the server that makes it
                available are free of viruses or other harmful components, or
                that the service or any services or items obtained through the
                service will meet your needs or expectations. The studio hereby
                disclaims all warranties of any kind, whether
              </p>
              <p>
                express or implied, statutory, or otherwise, including but not
                limited to any warranties of merchantability, non-infringement,
                and fitness for a particular purpose. The foregoing does not
                affect any warranties that cannot be excluded or limited under
                applicable law. The studio hereby disclaims all warranties of
                any kind, whether express or implied, statutory, or otherwise,
                including but not limited to any warranties of merchantability,
                non-infringement, and fitness for a particular purpose. The
                foregoing does not affect any warranties that cannot be excluded
                or limited under applicable law. The studio hereby disclaims all
                warranties of any kind, whether express or implied, statutory,
                or otherwise, including but not limited to any warranties of
                merchantability, non-infringement, and fitness for a particular
                purpose. The foregoing does not affect any warranties that
                cannot be excluded or limited under applicable law.
              </p>
            </div>
          </div>

          <div>
            <p>19. Limitation of Liability</p>
            <div>
              <p>
                The studio is not responsible for the consequences of following
                prompts, articles, or other publications on the website or
                service (including content provided by artificial intelligence
                computers). You acknowledge that the artificial intelligence
                computer providing content in the service and/or website is not
                an expert, may make mistakes, and you cannot rely on its answers
                or advice. Specifically, it cannot replace any professional
                advice. The studio is not responsible for the use of this
                service by the user's clients and the content provided by this
                service to the user's clients. The studio is not liable for the
                consequences of installing or sending any malicious software,
                phishing, or any malware. Other entities' practices regarding
                the service. The studio is not responsible for any interruptions
                in the operation of the service. The studio is not liable for
                the consequences of force majeure events, especially power
                outages, fires, atmospheric phenomena, wars, riots, strikes, and
                other similar events beyond the studio's control. Unless
                prohibited by law, you will indemnify us and our officers,
                directors, employees, and agents against any indirect, punitive,
                special, incidental, or consequential damages, however they
                arise (including attorney's fees and all related costs and
                expenses of litigation and arbitration, or at trial or on
                appeal, if any, whether or not litigation or arbitration is
                instituted), whether in an action of contract, negligence, or
                other tortious action, or arising out of or in connection with
                this Agreement, including without limitation any claim for
                personal injury or property damage, arising from this Agreement
                and any violation by you of any federal, state, or local laws,
                statutes, rules, or regulations, even if the studio has been
                previously advised of the possibility of such damage. Except as
                prohibited by law, if there is liability found on the part of
                the studio, it will be limited to the amount paid for the
                service, and under no circumstances will there be consequential
                or punitive damages. Some states do not allow the exclusion or
                limitation of punitive, incidental, or consequential damages, so
                the prior limitation or exclusion may not apply to you.
              </p>
            </div>
          </div>

          <div>
            <p>20. Guest/User Responsibility</p>
            <div>
              <p>
                Visitors and/or users bear full responsibility for the
                consequences of the authorized or unauthorized distribution of
                any content on the website or service. This particularly applies
                to intellectual property. This responsibility includes in
                particular exempting the studio from any claims made against the
                studio in this respect. The user is fully responsible for any
                content posted, presented, sent, or otherwise provided to the
                user's clients during the use of the service.
              </p>
            </div>
          </div>

          <div>
            <p>21. Service Interruption</p>
            <div>
              <p>
                The studio does not guarantee the permanent accessibility of the
                service or website. The studio reserves the right to interrupt
                the operation of the website or service or some of its
                functions. If intentional downtime is planned, the studio will
                notify at least 3 days in advance by posting relevant
                information or messages on the website or by email provided when
                setting up the account. If the operation of the website or
                service or some of its functions is unexpectedly interrupted,
                the studio will immediately notify by posting relevant
                information or messages on the website or by email provided when
                setting up the account. Accepting these provisions also means
                approving the service is not 100% reliable.
              </p>
            </div>
          </div>

          <div>
            <p>22. Personal Data Protection and Security</p>
            <div>
              <p>
                The studio diligently handles personal data and ensures the
                implementation of appropriate technical and organizational
                security measures. Detailed information about personal data
                protection can be found in the privacy policy. The studio is not
                responsible for the protection of personal data of users'
                clients using this service. The studio is not responsible for
                the content and rules on personal data protection and privacy on
                other websites (including websites referred to by links). The
                studio is not responsible for any damage resulting from browsing
                other websites or posting any personal data or other information
                on other websites. The studio recommends reading the rules on
                personal data protection and privacy on other websites
                (including websites referred to by links),
              </p>
            </div>
          </div>

          <div>
            <p>23. Termination</p>
            <div>
              <p>
                We may, at our sole discretion, for any reason (including but
                not limited to a breach of the terms), decide to terminate or
                suspend your account and immediately prohibit you from accessing
                the service without further notice or liability. If you wish to
                terminate your account, you simply need to stop using the
                service. By their nature, all terms and conditions that should
                remain in effect after termination shall remain in effect after
                termination, including but not limited to ownership provisions,
                warranty disclaimers, indemnities, and limitations of liability.
              </p>
            </div>
          </div>

          <div>
            <p>24. Governing Law</p>
            <div>
              <p>
                These terms are governed by and construed in accordance with the
                laws of the State of California, without regard to its conflict
                of law provisions. Our failure to enforce any right or provision
                of these terms will not be considered a waiver of those rights.
                If any provision of these terms is held to be invalid or
                unenforceable by a court, the remaining provisions of these
                terms will remain in effect. These terms constitute the entire
                agreement between us regarding our service and supersede and
                replace any prior agreements we might have between us regarding
                the service.
              </p>
            </div>
          </div>

          <div>
            <p>25. Service Changes</p>
            <div>
              <p>
                We reserve the right, at our sole discretion, to revoke or
                modify our service and any services or materials we offer
                through our service, without notice. We will not be liable if,
                for any reason, all or any part of the service is unavailable at
                any time or for any period. We may restrict access to parts of
                the service, or the entire service, to users, including
                registered users, from time to time.
              </p>
            </div>
          </div>

          <div>
            <p>26. Revision of Terms</p>
            <div>
              <p>
                We may revise the terms at any time by posting the revised terms
                on this website. It is your responsibility to review these terms
                periodically. Your continued use of this platform after the
                terms are revised signifies your acceptance of and agreement to
                the changes. You should check this page often so you are aware
                of any changes, as they are binding on you. Continuing to access
                or use our service after any revisions become effective means
                you agree to be bound by the revised terms. If you do not agree
                to the new terms, you are no longer authorized to use the
                service. If there is any discrepancy between these terms and the
                agreement (if one has been executed), the provisions of the
                agreement shall apply. The above sentence also refers to
                revisions of these terms introduced in the agreement.
              </p>
            </div>
          </div>

          <div>
            <p>27. Waiver and Severability</p>
            <div>
              <p>
                The studio's waiver of any term or condition set forth in the
                terms should not be deemed a further or continuing waiver of
                such term or condition, or a waiver of any other term or
                condition, and any failure of the studio to assert a right or
                provision under the terms does not constitute a waiver of such
                right or provision. If any provision of the terms is held by a
                court or other tribunal of competent jurisdiction to be invalid,
                illegal, or unenforceable for any reason, such provision shall
                be eliminated or limited to the minimum extent such that the
                remaining provisions of the terms will continue in full force
                and effect.
              </p>
            </div>
          </div>

          <div>
            <p>28. Acknowledgment</p>
            <div>
              <p>
                By using the services we offer or other services, you
                acknowledge that you have read these service terms and agree to
                be bound by them.
              </p>
            </div>
          </div>

          <div>
            <p>29. Contact Us</p>
            <div>
              <p>
                If at any time you wish to contact the studio, please send an
                email to:support
                <a href="mailto:support@speci-chatbot.com">
                  @speci-chatbot.com.
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
