import React from "react";
import style from "./../style.module.scss";

export default function Response() {
  return (
    <div className={style.res}>
      <h1>Optimizing SpeciChatbot Chatbot Responses</h1>
      <br />
      <p>
        While SpeciChatbot’s AI-driven chatbots are invaluable tools for
        automating customer interactions, it is crucial to acknowledge the
        existing limitations embedded within current AI technology. There might
        be instances where chatbots generate responses that either lack accuracy
        or deviate from the content outlined in provided documents— anomalies
        commonly identified as 'hallucinations' within the realm of AI.
        Fortunately, SpeciChatbot provides various methods to alleviate these
        concerns, allowing for meticulous refinement and optimization of your
        chatbot’s responses. Below, we guide you on how to enhance the accuracy
        and relevance of your chatbot’s replies.
      </p>
      <br />
      <br />

      <h2>Refine the Base Prompt</h2>
      <br />
      <p>
        The base prompt fundamentally influences your chatbot’s behavior and the
        nature of its responses. To confine your bot's replies strictly to
        questions pertaining to a specified document, you need to clearly
        articulate this restriction in the base prompt. For example, you may
        declare, 'Your responses should be exclusively based on the information
        contained in [document name].' Below is the default base prompt:
      </p>
      <br />
      <br />

      <blockquote>
        <p>
          Envision yourself as a document with which I am engaging in a
          conversation. Respond under the alias 'AI Assistant', and restrict
          your responses to the information provided in the given document.
          Should a query lack a corresponding answer within the document,
          respond with 'Hmm, I am not sure.' and cease further commentary.
          Refrain from addressing inquiries unrelated to the document’s content
          and consistently maintain this designated character throughout the
          interaction.
        </p>
      </blockquote>
      <br />
      <br />

      <h2>Ensure Readability of Uploaded Data Sources</h2>
      <br />
      <p>
        The effectiveness of your chatbot’s responses is predominantly
        determined by the quality of the data sources supplied. Since
        SpeciChatbot relies on readable text to craft responses, it's imperative
        that the websites or PDFs uploaded encompass clear and legible text. Be
        advised that SpeciChatbot is incapable of processing images, videos, or
        any non-textual elements within documents. Additionally, certain
        websites resist scraping techniques, which could be why your chatbot
        might struggle to address questions based on your website's content. To
        circumvent this limitation, consider manually copying and pasting
        information into the data sources as text, or opting to upload the
        information in PDF format.
      </p>
      <br />
      <br />

      <h2>Utilize the "Revise" Feature and Q&A Data Type</h2>
      <br />
      <p>
        The 'revise' feature can be accessed from the dashboard within your
        conversation history and serves as a tool for fine-tuning responses
      </p>
      <br />
      <p>
        If the response provided by your chatbot to a specific query does not
        meet your satisfaction, the 'revise' feature allows you to modify the
        given answer, ensuring improved accuracy in future interactions. Further
        enhancing the quality of generated responses, the Q&A data type enables
        your chatbot to craft more precise answers by referencing
        pre-established questions and answers. Note that any responses you
        modify will be conveniently located in the Q&A tab under 'Manage
        Sources' for your reference and management.
      </p>
      <br />
      <br />

      <h2>Leverage the Power of GPT-4</h2>
      <br />
      <p>
        Should you aspire for your chatbot to craft responses that are more
        nuanced and sophisticated, it would be prudent to consider deploying the
        GPT-4 model. Although this option entails a higher expenditure of
        message credits, the investment is justified by the model’s ability to
        generate responses that are not only more accurate but also demonstrably
        aware of the surrounding context. Be aware that you have the flexibility
        to select and switch between different language models as needed.
      </p>
      <br />
      <br />

      <h2>Create a Document Mapping Website URLs to Page Names</h2>
      <br />
      <p>
        In the event that your bot generates erroneous URLs resulting in 404
        errors, consider creating a PDF document that meticulously maps all
        valid URLs to their corresponding page names. This approach proves
        particularly beneficial if your chatbot functions on a website
        encompassing multiple pages. A comprehensive document delineating the
        relationship between URLs and page names enhances your chatbot's
        capacity to accurately interpret and respond to user inquiries
        pertaining to various pages. Furthermore, you have the option to
        incorporate these links utilizing a Q&A format, adhering to the
        recommendations previously outlined.
      </p>
      <br />
      <br />

      <h2>Next Steps</h2>
      <br />
      <p>
        Through the adoption of these strategies, you can markedly improve your
        SpeciChatbot's capacity to deliver valuable responses, thereby fostering
        more fruitful and successful interactions.
      </p>
    </div>
  );
}
