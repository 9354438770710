import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Button, Tabs, Upload, Card, Form, Input, message } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { baseUrl, get, jsonPost, post } from "../../utils/ajax";
import { useNavigate } from "react-router-dom";

const FileItem = (param) => {
  const props = {
    accept: "text/html,.pdf,.doc,.docx,.txt",
    name: "file",
    headers: {
      token: JSON.parse(localStorage.getItem("user") || "{}")?.token || "",
    },
    action: `${baseUrl}/chats_bp/upload_file`,
    beforeUpload: (file) => {
      const isType = ["application/pdf", "text/plain"].includes(file.type);
      if (!isType) {
        message.error(`File does not conform to format`);
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        message.error("must smaller than 2MB!");
        return false;
      }
      return true;
    },
    fileList: param.fileList,
    onChange: (info) => {
      // 更新文件列表
      param.setFileList(info.fileList);
    },
    onRemove: (info) => {
      if (info.knowledge_id) {
        const data = {
          knowledge_id: info.knowledge_id,
          type: "file",
        };
        param.delKnow && param.delKnow(data);
      } else {
        // 更新文件列表
        const newFile = param.fileList.filter((val) => val.uid !== info.uid);
        param.setFileList(newFile);
      }
      return false;
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>Upload Files</p>
      <br />
      <br />
      <Form.Item
        style={{ width: "45%", margin: "0 auto" }}
        extra="NOTE: Uploading a PDF using safari doesn't work, we're looking into the issue. Make sure the text is OCR'd, i.e. you can copy it."
        className="w100"
      >
        <Form.Item
          name="files"
          valuePropName="file"
          getValueFromEvent={normFile}
          noStyle
          rules={
            [
              // {
              //   required: true,
              //   message: "Please select your files!",
              // },
            ]
          }
        >
          <Upload.Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Drag & drop files here, or click to select files
            </p>
            <p className="ant-upload-hint">
              Supported File Types: .pdf, .doc, .docx, .txt
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
    </>
  );
};

const Website = (param) => {
  const isHasKid = (key) => {
    return param.chatbot_id && !!param.info.websiteUrl[key]?.knowledge_id;
  };

  return (
    <>
      <br />
      <p>
        Once website security measures are fully implemented, this feature will
        be promptly restored. In the meantime, you can input website content in
        formats such as TXT, Q&A, and PDF to achieve equivalent functionality.
      </p>
      <Form.List name="webUrls">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Card key={key} style={{ margin: "16px 0" }}>
                <Form.Item
                  label="Url"
                  {...restField}
                  name={[name, "url"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Website Url",
                    },
                  ]}
                >
                  <Input
                    readOnly={isHasKid(key)}
                    size="large"
                    placeholder="input your website Url!"
                  />
                </Form.Item>
                <DeleteOutlined
                  onClick={() => {
                    const { knowledge_id } =
                      (param.info.websiteUrl || []).find(
                        (item, index) => index == key
                      ) || {};

                    if (!knowledge_id) {
                      remove(name);
                      return;
                    }

                    // "type must be in file,text,qa,url"
                    const data = {
                      knowledge_id,
                      type: "url",
                    };

                    console.log("data -> :", data);

                    param.delKnow && param.delKnow(data);
                  }}
                />
              </Card>
            ))}
            <Form.Item>
              <Button disabled type="primary" onClick={() => add()}>
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

const Answer = (param) => {
  return (
    <Form.List name="qas">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Card key={key} style={{ margin: "16px 0" }}>
              <DeleteOutlined
                onClick={() => {
                  const { knowledge_id } =
                    (param.info.qa || []).find((item, index) => index == key) ||
                    {};

                  if (!knowledge_id) {
                    remove(name);
                    return;
                  }

                  // "type must be in file,text,qa,url"
                  const data = {
                    knowledge_id,
                    type: "qa",
                  };

                  console.log("data -> :", data);

                  param.delKnow && param.delKnow(data);
                }}
              />
              <Form.Item
                label="Question"
                {...restField}
                name={[name, "question"]}
                rules={[
                  {
                    required: true,
                    message: "Please input your Question",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="questions" />
              </Form.Item>
              <Form.Item
                {...restField}
                label="Answer"
                name={[name, "answer"]}
                rules={[
                  {
                    required: true,
                    message: "Please input your Answer",
                  },
                ]}
              >
                <Input.TextArea rows={8} placeholder="answer" />
              </Form.Item>
            </Card>
          ))}
          <Form.Item>
            <Button type="primary" onClick={() => add()}>
              Add
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default function Index({ info = {}, refesh }) {
  const [type, setType] = useState("file");
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const { chatbot_id } = info;

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const delKnow = ({ knowledge_id, type }) => {
    post(
      "/chats_bp/delete_knowledge",
      {
        knowledge_id,
        type,
        chatbot_id,
      },
      {
        token: user.token,
      }
    ).then((res) => {
      refesh && refesh();
    });
  };

  const items = [
    {
      key: "file",
      label: `Files`,
      forceRender: true,
      children: (
        <FileItem
          fileList={fileList}
          setFileList={setFileList}
          delKnow={delKnow}
        />
      ),
    },
    {
      key: "text",
      label: `Text`,
      forceRender: true,
      children: (
        <>
          <Form.Item
            label=""
            style={{ width: "80%", margin: "0 auto" }}
            name="text"
            rules={
              [
                // {
                //   required: true,
                //   message: "Please input your text!",
                // },
                // {
                //   min: 200,
                //   message: "Minimum length 200!",
                // },
              ]
            }
          >
            <Input.TextArea placeholder="data" rows={20} />
          </Form.Item>
        </>
      ),
    },
    {
      key: "url",
      label: `Website`,
      forceRender: true,
      children: (
        <Website chatbot_id={chatbot_id} info={info} delKnow={delKnow} />
      ),
    },
    {
      key: "answer",
      label: `Q&A`,
      forceRender: true,
      children: <Answer info={info} delKnow={delKnow} />,
    },
  ];

  const onChange = (key) => {
    setType(key);
  };

  const onFinish = (values) => {
    // console.log("values -> :", values);
    const data = { ...values };

    data.files = (values.files || []).map((file) => ({
      fileId: file.file_id || file.response?.file_id,
      fileName: file.name,
      knowledge_id: file.knowledge_id || "",
    }));

    // data.qas = data.qa || [];

    data.urls = data.webUrls || [];

    data.text = data.text
      ? {
          text: data.text || "",
          knowledge_id: info?.text?.knowledge_id || "",
        }
      : null;

    if (
      (!data.files || !data.files.length) &&
      (!data.answers || !data.answers.length) &&
      (!data.urls || !data.urls.length)
    ) {
      message.error(" Please add your sources");
      return;
    }

    if (chatbot_id) {
      data.chatbot_id = chatbot_id;
    }

    // console.log("data -> :", data);

    jsonPost("/chats_bp/import_knowledge", data, {
      token: user.token,
    }).then((res) => {
      if (res.status == "success") {
        navigate("/my-chatbots");
        message.success("successfully");
      } else {
        message.error("create error");
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (chatbot_id) {
      form.setFieldValue("text", info.text?.text);
      form.setFieldValue("files", info.files);
      setFileList(info.files);
      form.setFieldValue("qas", info.qa);
      form.setFieldValue("webUrls", info.websiteUrl);
      form.setFieldValue("robot_name", info.name);
    }
    return () => {};
  }, [info]);

  return (
    <div className={style.wrap}>
      <div className={`${style.center} sub-center-padding`}>
        {!chatbot_id && <h1>Data Sources</h1>}

        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <br />
          <Form.Item
            name="robot_name"
            style={{ width: "45%", margin: "0 auto" }}
            className="w100"
          >
            <Input placeholder="robot name" />
          </Form.Item>
          <br />

          <Form.Item
            name="temperature"
            style={{ width: "45%", margin: "0 auto" }}
            className="w100"
          >
            <Input placeholder="robot temperature" />
          </Form.Item>
          <br />

          <Form.Item
            name="prompt"
            style={{ width: "45%", margin: "0 auto" }}
            className="w100"
          >
            <Input.TextArea placeholder="robot prompt" rows={6} />
          </Form.Item>
          <br />

          <Tabs centered activeKey={type} items={items} onChange={onChange} />
          <br />
          <br />
          <Card style={{ width: "75%", margin: "0 auto" }} className="w100">
            {/* <p>Included sources:</p> */}
            {/* <p>Total detected characters: 0</p> */}

            {type == "text" && (
              <p>
                Total detected characters: {user?.subProduct?.token_number || 0}{" "}
                limit
              </p>
            )}
            <br />
            <Button
              size="large"
              style={{ background: "#000", color: "#fff" }}
              block
              htmlType="submit"
            >
              {chatbot_id ? "Retrain Chatbot" : "Create Chatbot"}
            </Button>
          </Card>
        </Form>
      </div>
    </div>
  );
}
